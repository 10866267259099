import { Fragment, useRef } from 'react';

import * as THREE from 'three';
import { Canvas, useFrame } from 'react-three-fiber';
import { Physics, useBox, usePlane, useSphere } from '@react-three/cannon';

const MAP_X = 42;
const MAP_Y = 28;

const THIC = 1;
const OPEN = 1;

function rndRange(min, max) {
  return Math.random() * (max - min) + min;
}

const DRAG_STATE = {
  isMouseDown: false,
  position: null
};
function useDrag(onDrag, onDragEnd) {
  function onPointerDown(e) {
    onDrag(e);
    DRAG_STATE.isMouseDown = true;
  }

  function onPointerUp(e) {
    onDrag(e);
    DRAG_STATE.isMouseDown = false;
    onDragEnd(e);
  }

  function onPointerMove(e) {
    if (DRAG_STATE.isMouseDown) {
      onDrag(e);
    }
  }

  return { onPointerDown, onPointerUp, onPointerMove };
}

function Sphere(props) {
  const { mass = 1, rotation = [0, 0, 0], position, color, power = 1 } = props;

  const size = props.size / 2;

  const [ref, api] = useSphere(() => ({
    mass,
    angularDamping: 0.0,
    linearDamping: 0.0,
    sleepSpeedLimit: 0.6,
    sleepTimeLimit: 0.6,
    position,
    rotation,
    args: size,
    material: {
      friction: 0
    }
  }));

  useFrame(() => {
    // console.log(ref.current)

    if (Math.abs(ref.current.position.x) > MAP_X / 2 + 2) {
      if (ref.current.position.x < 0) {
        window.score[0] += 1;
      } else {
        window.score[1] += 1;
      }
      document.querySelector(
        '.score'
      ).innerHTML = `${window.score[0]} - ${window.score[1]}`;

      api.velocity.set(0, 0, 0);
      api.position.set(0, 0, 1);
      api.velocity.set(0, 0, 0);
    }
  });

  return (
    <Fragment>
      <mesh ref={ref} castShadow>
        <sphereBufferGeometry attach="geometry" args={[size, 32, 32]} />

        <meshLambertMaterial
          attach="material"
          color={color}
          side={THREE.DoubleSide}
        />
      </mesh>
      <SphereGhost
        power={power}
        parent={ref}
        parentApi={api}
        position={[0, 0, -1]}
        color={'#7f8c8d'}
        size={0.5}
      />
    </Fragment>
  );
}

function SphereGhost(props) {
  const { color, parent, parentApi, position, power } = props;

  const size = props.size / 2;

  const ref = useRef();
  const lineRef = useRef();

  const points = [new THREE.Vector3(0, 0, -1), new THREE.Vector3(0, 0, -1)];

  const lineGeometry = new THREE.BufferGeometry().setFromPoints(points);

  useFrame(() => {
    if (!DRAG_STATE.position) return;

    const height = DRAG_STATE.isMouseDown ? size : -1 * size;
    const heightFinal = DRAG_STATE.isMouseDown
      ? parent.current.position.z
      : -1 * size;

    lineRef.current.geometry = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(DRAG_STATE.position.x, DRAG_STATE.position.y, height),
      new THREE.Vector3(
        parent.current.position.x,
        parent.current.position.y,
        heightFinal
      )
    ]);

    if (!DRAG_STATE.isMouseDown && ref.current.down) {
      const x = DRAG_STATE.position.x - parent.current.position.x;
      const y = DRAG_STATE.position.y - parent.current.position.y;

      parentApi.velocity.set(power * x, power * y, 0);
    }

    ref.current.down = DRAG_STATE.isMouseDown;

    ref.current.position.set(
      DRAG_STATE.position.x,
      DRAG_STATE.position.y,
      height
    );
  });

  return (
    <Fragment>
      <mesh ref={ref} castShadow position={position}>
        <sphereBufferGeometry attach="geometry" args={[size, 32, 32]} />
        <meshLambertMaterial
          attach="material"
          color={color}
          side={THREE.DoubleSide}
        />
      </mesh>
      <line ref={lineRef} geometry={lineGeometry}>
        <lineBasicMaterial attach="material" color={'#7f8c8d'} lineWidth={1} />
      </line>
    </Fragment>
  );
}

function Box(props) {
  const {
    size,
    mass = 1,
    rotation = [0, 0, 0],
    position,
    color,
    fixed = false
  } = props;

  const finalSize = typeof size == 'number' ? [size, size, size] : size;
  const finalPosition = [
    position[0],
    position[1],
    position[2] + finalSize[2] / 2
  ];

  const [ref] = useBox(() => ({
    mass,
    position: finalPosition,
    rotation,
    args: finalSize,
    type: fixed ? 'Static' : 'Dynamic'
  }));

  return (
    <mesh ref={ref} castShadow>
      <boxBufferGeometry attach="geometry" args={finalSize} />

      <meshLambertMaterial
        attach="material"
        color={color}
        side={THREE.DoubleSide}
      />
    </mesh>
  );
}

function Plane({ color, size = [1024, 1024], position, rotation, ...props }) {
  const [ref] = usePlane(() => ({
    position,
    rotation
  }));
  return (
    <mesh ref={ref} receiveShadow {...props}>
      <planeBufferGeometry attach="geometry" args={size} />
      <meshPhongMaterial attach="material" color={color} />
    </mesh>
  );
}

function Goal(props) {
  const p = props.position;

  return (
    <Fragment>
      <Box
        fixed={true}
        position={[p[0] + 0.5, p[1] + 1.75, p[2]]}
        rotation={[0, 0, Math.PI / 2]}
        color={'#7f8c8d'}
        size={[0.5, THIC, 3]}
      />

      <Box
        fixed={true}
        position={[p[0] + 0.5, p[1] - 1.75, p[2]]}
        rotation={[0, 0, Math.PI / 2]}
        color={'#7f8c8d'}
        size={[0.5, THIC, 3]}
      />

      <Box
        fixed={true}
        position={[p[0] + 0.5, p[1], p[2] + 3]}
        rotation={[0, 0, Math.PI / 2]}
        color={'#7f8c8d'}
        size={[4, THIC, 0.5]}
      />
    </Fragment>
  );
}

function App() {
  return (
    <Canvas
      shadowMap
      pixelRatio={Math.min(window.devicePixelRatio, 2)}
      concurrent={true}
      camera={{
        position: [0, -16, 32],
        zoom: window.innerWidth > 1024 ? 1.25 : 0.5
      }}
      gl={{ alpha: false }}
      style={{ height: window.innerHeight, width: window.innerWidth }}
    >
      <hemisphereLight intensity={0.25} />

      <spotLight
        position={[32, 0, 32]}
        angle={0.5}
        penumbra={1}
        intensity={2}
        castShadow
        shadow-mapSize-width={512}
        shadow-mapSize-height={512}
      />

      <spotLight
        position={[-32, 0, 32]}
        angle={0.5}
        penumbra={1}
        intensity={0.75}
      />

      <pointLight position={[32, 0, 32]} intensity={0.25} />

      <Physics
        allowSleep={false}
        iterations={4}
        gravity={[0, 0, -9]}
        defaultContactMaterial={{
          restitution: 0.6,
          friction: 0.008,
          frictionEquationRegularization: 16
        }}
      >
        <Goal position={[MAP_X / -2 - 1 + 4, 0, 0]} />
        <Goal position={[MAP_X / 2 - 4, 0, 0]} />

        <Box
          position={[rndRange(-8, 8), rndRange(-8, 8), 0]}
          rotation={[0, 0, rndRange(0, 6)]}
          color={'#7f8c8d'}
          mass={5}
          size={[0.5, 2, 2]}
        />

        <Box
          position={[rndRange(-8, 8), rndRange(-8, 8), 0]}
          rotation={[0, 0, rndRange(0, 6)]}
          color={'#7f8c8d'}
          mass={5}
          size={[0.5, 2, 2]}
        />

        <Box
          position={[rndRange(-8, 8), rndRange(-8, 8), 0]}
          rotation={[0, 0, rndRange(0, 6)]}
          color={'#7f8c8d'}
          mass={5}
          size={[0.5, 2, 2]}
        />

        <Sphere
          position={[rndRange(-8, 8), rndRange(-8, 8), 0.5]}
          rotation={[0, 0, 0]}
          color={'#f02509'}
          size={1}
          power={-1}
        />

        <Sphere
          position={[rndRange(-8, 8), rndRange(-8, 8), 0.5]}
          rotation={[0, 0, 0]}
          color={'#f02509'}
          size={1}
          power={-1}
        />

        <Sphere
          position={[rndRange(-8, 8), rndRange(-8, 8), 0.5]}
          rotation={[0, 0, 0]}
          color={'#f02509'}
          size={1}
          power={-1}
        />

        <Sphere
          position={[rndRange(-8, 8), rndRange(-8, 8), 0.5]}
          rotation={[0, 0, 0]}
          color={'#2ecc71'}
          size={1}
        />

        <Box
          fixed={true}
          position={[0, MAP_Y / 2 + THIC / 2, 0]}
          rotation={[0, 0, 0]}
          color={'#fff'}
          size={[MAP_X, THIC, 3]}
        />

        <Box
          fixed={true}
          position={[0, MAP_Y / -2 - THIC / 2, 0]}
          rotation={[0, 0, 0]}
          color={'#fff'}
          size={[MAP_X, THIC, 3]}
        />

        <Box
          fixed={true}
          position={[MAP_X / -2 - THIC / 2, MAP_Y / 4 + OPEN + THIC / 2, 0]}
          rotation={[0, 0, Math.PI / 2]}
          color={'#fff'}
          size={[MAP_Y / 2 - OPEN * 2 + THIC, THIC, 3]}
        />

        <Box
          fixed={true}
          position={[MAP_X / -2 - THIC / 2, MAP_Y / -4 - OPEN - THIC / 2, 0]}
          rotation={[0, 0, Math.PI / 2]}
          color={'#fff'}
          size={[MAP_Y / 2 - OPEN * 2 + THIC, THIC, 3]}
        />

        <Box
          fixed={true}
          position={[MAP_X / 2 + THIC / 2, MAP_Y / 4 + OPEN + THIC / 2, 0]}
          rotation={[0, 0, Math.PI / 2]}
          color={'#fff'}
          size={[MAP_Y / 2 - OPEN * 2 + THIC, THIC, 3]}
        />

        <Box
          fixed={true}
          position={[MAP_X / 2 + THIC / 2, MAP_Y / -4 - OPEN - THIC / 2, 0]}
          rotation={[0, 0, Math.PI / 2]}
          color={'#fff'}
          size={[MAP_Y / 2 - OPEN * 2 + THIC, THIC, 3]}
        />

        <Plane
          color="#fff"
          {...useDrag(
            (e) => {
              DRAG_STATE.position = e.point;
            },
            (e) => {
              // console.info('>', e.point);
              DRAG_STATE.position = e.point;
            }
          )}
        />
      </Physics>
    </Canvas>
  );
}

export default App;

// <Plane color="#ddd" position={[-16, 0, 0]} rotation={[0, 0.9, 0]} />
// <Plane color="#ddd" position={[16, 0, 0]} rotation={[0, -0.9, 0]} />
// <Plane color="#ddd" position={[0, 12, 0]} rotation={[0.9, 0, 0]} />
// <Plane color="#ddd" position={[0, -12, 0]} rotation={[-0.9, 0, 0]} />
